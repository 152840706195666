.container {
  width: 740px;
  margin: auto;
  font-family: Verdana;
  font-size: 12px;
  color: #304385;

  a {
    color: #304385;

    &:hover {
      color: #8c5f00;
    }
  }

  hr {
    margin: 20px 0;
    border: none;
    background: #cccccc;
    height: 1px
  }

  .links {
    margin-top: 10px;
    margin-bottom: 10px;

    .col {
      display: inline-block;
      text-align: center;
      width: 100%;

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }
    }
  }

  .main {
    width: 700px;
    margin: auto;
    border: 1px solid #b3b3b3;
    padding: 20px 20px 10px;
    overflow: auto;

    .logos {
      height: 65px;

      #mainLogo {
        float: left;
      }

      #secondaryLogo {
        float: right;
      }
    }

    .search {
      .label {
        margin-bottom: 15px;
      }
    }

    .confirm {
      margin-top: 10px;
      height: 10px;

      form {
        display: none;
        float: right;
      }
    }

    .legend {
      @extend .selectItem;

      .openingHours {
        float: left !important;
        display: inline;
      }

      .atm {
        float: left !important;
        display: inline;
      }

      .parking {
        float: left !important;
        display: inline;
      }

      .extended {
        float: left !important;
        display: inline;
      }

      .service {
        float: left !important;
        display: inline;
      }

      .label {
        display: inline;
        color: #304385;
      }

      .item {
        height: 30px;
        width: 100%;
      }
    }

  }

}



.selectItem {
  .item {
    float: left;
    color: #535353;
    font-size: 11px;
    margin-right: 15px;
    overflow: hidden;
  }

  .city {
    @extend .item;
    width: 100px;
  }

  .cityPart {
    @extend .item;
    width: 120px;
  }

  .address {
    @extend .item;
    width: 220px;
  }

  .openingHours {
    background: url("../images/ico_7.gif");
    width: 21px;
    height: 21px;
    float: right;
    margin-right: 15px;
  }

  .atm {
    background: url("../images/ico_1.png");
    width: 21px;
    height: 21px;
    float: right;
    margin-right: 15px;
  }

  .parking {
    background: url("../images/ico_3.png");
    width: 21px;
    height: 21px;
    float: right;
    margin-right: 15px;
  }

  .service {
    background: url("../images/ico_2.png");
    width: 21px;
    height: 21px;
    float: right;
    margin-right: 15px;
  }

  .extended {
    background: url("../images/ico_4.png");
    width: 21px;
    height: 21px;
    float: right;
    margin-right: 15px;
  }

  .placeholder {
    width: 21px;
    height: 21px;
    float: right;
    margin-right: 15px;
  }

  .kind {
    @extend .item;
    width: 40px;
    float: right;
  }

  &.select2-results__option--highlighted {
    background-color: #2f4581 !important;
  }

  &.select2-results__option--highlighted {
    div {
      color: white;
    }
  }
}

.select2-selection__choice {
  @extend .selectItem;
  background: none !important;
  border: none !important;
  width: 700px !important;
  font-size: 11px !important;
}

.select2-selection--single {
  @extend .selectItem;

  font-size: 11px !important;

  .city {
    margin-right: 5px;
  }

  .cityPart {
    margin-right: 5px;
  }

  .address {
    width: 240px;
  }

  .openingHours {
    margin-top: 3px;
    margin-right: 5px;
  }

  .parking {
    margin-top: 3px;
    margin-right: 5px;
  }

  .extended {
    margin-top: 3px;
    margin-right: 5px;
  }

  .service {
    margin-top: 3px;
    margin-right: 5px;
  }

  .atm {
    margin-top: 3px;
    margin-right: 5px;
  }

  .placeholder {
    margin-top: 3px;
    margin-right: 5px;
  }
}

.select2-container {
  width: 700px !important;

  .select2-selection {
    border-radius: 0 !important;
    border: 1px solid #2f4581 !important;
  }

  .select2-dropdown {

    .select2-results {
      li {
        height: 22px !important;
        border-bottom: 1px solid #b3b3b3 !important;

        @extend .selectItem;
      }

    }
  }

}

.select2-results__message {
  color: #304385;
  font-size: 12px;
  font-family: Verdana;
}

.select2-results__option {
  font-family: Verdana;
  padding: 6px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

#tooltip {
  position:absolute;
  left:0;
  top:-1000px;
  z-index:100000;
  background: white;
  border: 1px solid #314584;
  color: #000;
  font-size: 10px;
  padding: 10px;
  max-width: 290px;
  text-align: left;
  line-height: 1.3;
  font-family: Verdana
}

input[type='submit'] {
  border: 1px solid #2f4581;
  height: 24px;
  background-color: #fecf43;
  cursor: pointer;
  color: #2f4581;
  font-family: Tahoma;
  font-size: 11px;
  width: 60px;
  text-align: center;
}